<template>
  <div>
    <div class="presessionDetailBox" v-if="event && last_state">
      <div class="titleBox">
        جزئیات جلسه پیش‌مشاوره
      </div>
      <div class="mt-5">
        <div class="d-flex">
          <div class="dataBox">
            <div class="titleDataBox">تاریخ جلسه:</div>
            <div class="descDataBox">
              {{ event.created_at | dateFilter }}
            </div>
          </div>
          <div class="dataBox">
            <div class="titleDataBox">آخرین وضعیت:</div>
            <div class="descDataBox">
              <div class="stateBox" v-if="last_state">
                {{ last_state.type | stateFilter }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="doctorArea mt-10" v-if="last_state.type == 'final_result_mm'">
        <div class="titleBox">
          لیست‌ دکتر‌های پیشنهادی
        </div>
        <v-row class="pt-5" v-if="last_state.payload?.therapists?.length ">
          <v-col cols="12" lg="4" v-for="(doctor,i) in last_state.payload.therapists" :key="i">
            <div class="doctorBox">
              <div class="text-center">
                <img :src="doctor.user.avatar" width="60%" style="margin: 0px auto" alt="">
              </div>
              <div class="text-center">
                <div class="doctorName">
                  {{ doctor.user.full_name }}
                </div>
                <div>
                  <a target="_blank" :href="doctor.resume_link">
                    <v-btn x-small color="primary" elevation="0" class="my-3">
                      نمایش رزومه
                    </v-btn>
                  </a>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="text-center pa-10" v-else>
          <v-icon large color="red">
            mdi-alert
          </v-icon>
          <div class="font-weight-bold">
            دکتر مناسبی برای شما پیدا نشد.
          </div>
          <div>
            شما می‌توانید از طریق پشتیبانی دکتر مورد نظر خود را انتخاب کنید.
          </div>
        </div>
      </div>
      <div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      event: null,
      last_state: null,
    }
  },
  mounted() {
    this.requesting('user', 'getEventStatus', {id: this.$route.params.id}).then((resp) => {
      this.event = resp.data
      this.last_state = resp.data.states[0]
    })
  },
  filters: {
    stateFilter(value) {
      const mappings = {
        data_submitted: 'پایان جلسه',
        sent_to_chatbot: 'ارسال دیتا برای هوش‌مصنوعی',
        data_ready_for_mm: 'اماده سازی دیتا برای پیشنهاد دکتر',
        data_sent_to_mm: 'اماده سازی لیست دکتر‌ها',
        final_result_mm: 'پایان فرایند',
        initiated: 'شروع فرایند',
        ai_ended: 'پایان جلسه با هوش‌مصنوعی',
      };

      return mappings[value] || value;
    }
  }
}
</script>

<style scoped>
.doctorName {
  font-family: bold !important;
  font-size: 12px;
}

.presessionDetailBox {
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #DDDDDD;
  width: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 40px auto;
}

.titleBox {
  font-size: 15pt;
  border-bottom: 1px solid #DDDDDD;
  padding: 10px 0px;
  font-family: bold !important;
}

.dataBox {
  display: flex;
  margin-left: 30px;

}

.titleDataBox {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.stateBox {
  color: #1cba88;
  border: 1px solid #1cba88;
  font-family: bold !important;
  background-color: rgba(28, 186, 136, 0.1);
  padding: 5px;
  border-radius: 10px;
  font-size: 10px;
}
</style>